// https://github.com/gatsbyjs/gatsby-starter-video 참조할 것

import React, { useEffect } from "react"
import { graphql } from "gatsby"
import $ from "jquery"

import { Disqus, CommentCount } from 'gatsby-plugin-disqus'

import Layout from "../components/js/layout"
import SEO from "../components/js/seo"

import extrasData from "./../data/extras.yaml"

import "../components/css/post/video-post.css"
import "../components/css/markdown.css"

// import banner_0 from "./../images/banners/banner_0.png"
// import banner_1 from "./../images/banners/banner_1.png"
// import banner_2 from "./../images/banners/banner_2.png"

import copy from 'copy-to-clipboard';
import toastr from 'toastr'

const ExtraPostTemplate = ({ data, location }) => {

  const post = data.markdownRemark
  const siteTitle = data.site.siteMetadata.title

  const extraData = extrasData.filter((extra) => {
    return extra.idx === post.frontmatter.idx
  })[0]

  let disqusConfig = {
    url: `https://www.yalco.kr${location.pathname}`,
    identifier: String(extraData.idx),
    title: extraData.title,
  }

  useEffect(() => {
    $('deckgo-highlight-code').off('click').on('click', (e) => {
      const text = $(e.target).find('code').text()
      copy(text);
      toastr.success('코드가 복사되었습니다.')
    })
  })

  return (
    <Layout location={location} title={siteTitle}>
      <SEO
        title={extraData.title}
        description={extraData.description}
      />
      <article className="post markdown-body">
        {/* <h1>
          {extraData.title}
        </h1> */}
        <section dangerouslySetInnerHTML={{ __html: post.html }} />
      </article>

      <br></br>
      <br></br>

      {/* <section className="centered">
        <a href={bannerLink} target="_new">
          <img className="banner" alt="banner" src={banner}></img>
        </a>
      </section> */}

      <br></br>
      <br></br>

      <section className="centered">
        <CommentCount config={disqusConfig} placeholder={'...'} />
        <Disqus config={disqusConfig} />
      </section>

      <footer>
      </footer>
    </Layout>
  )
}

export default ExtraPostTemplate

export const pageQuery = graphql`
  query ExtraPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        idx
      }
    }
  }
`